/* .App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .App-logo {
   height: 40vmin; 
  pointer-events: none;
} */


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10050s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/*==================== BANNER MEDIA QUERRY =========================*/

html,body{
  max-width: 100%;
  overflow-x: hidden;
}
@media(min-width: 2560px){.mobile{display: none;}}
@media(min-width: 1440px){.mobile{display: none;}}
@media(min-width: 1024px){.mobile{display: none;}}
@media(min-width: 768px){.mobile{display: none;}}
@media(max-width: 576px){.desktop{ display: none}}
@media (max-width: 425px){.desktop{ display: none}}
@media (max-width: 375px){.desktop{ display: none}}
@media (max-width: 320px){.desktop{ display: none}}
.menutop {
  /* background: red; */
  padding: 10px 0px;
}
.navbar-light .navbar-toggler {
  color: rgb(255 255 255)!important;
  border-color: rgb(109 106 121)!important;
  background: radial-gradient(#ffffffe6, #7963d2)!important;
}

.search-input {
  padding-right: 2.5rem;
  /* Adjust based on icon size */
}

.search-icon {
  position: absolute;
  right: 10px;
  /* Adjust to position the icon */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure the icon doesn't interfere with typing */
}

@media(max-width:2560px) {
  
  /* .vcvc .uu{
    padding-top: 10px;
    background: red;
  } */
.news .uc {
  padding-top: 10px;
  background:linear-gradient(92deg, #2dc977, #aef5d600);
  border-radius: 20px 0px 0px 20px;
}
.news span{
  font-weight: 800;
}

.rr .uc {
  padding-top: 10px;
  background:linear-gradient(92deg, #b798f1, #aef5d600)!important;
  border-radius: 20px 0px 0px 20px;
}
.rr span{
  font-weight: 800;
}





  .vcvc .uc{
    padding-top: 10px;
    background: linear-gradient(92deg, #e31e24, #aef5d600);
    border-radius: 20px 0px 0px 20px;
  }


  .vcvc span{
    font-weight: 800;
  }
  .lloo .popp{
    font-Weight:700;
    color:#7963d2;
    font-Size:17px;
  }
  .sticky-top {
    position: sticky!important;
    top: 0!important;
    z-index: 1020!important;
    /* background: red; */
}
  .Apogo {
   width: 35%!important;
}
  /* .active{
    border-top:2px solid red;
  } */
  .menutop{
    background: url(../public/28.png);
  }
  /* nav{
    padding-left: 0px!important;
  } */
  /* .menutop img {
    width: 70%;
  } */

  .menutop p {
    color: #5c5b5c;
    font-weight: 600;
    font-size: 20px;
  }
  .menutop input{
    border-radius: 20px;
    background: #fff;
    color: black;
  }
  .menutop .ii{
    padding: 0px;
  }

  nav {
    /* background:#9591a85c; */
    z-index: 9;
  }
  nav{
    background: rgb(121, 99, 210);
    padding: 10px 190px;
  }

  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 20px;
    /* padding-left: 50px; */
  }

  .navbar-light .navbar-nav .active>.nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show>.nav-link {
    color: rgb(255 255 255);
  }

  .carro {
    position: relative;
    margin-top: -62px;
  }

  .lloo {
    padding: 30px 0px;
  }

  .news {
    background: #b798f1;
    padding: 50px 0px;
  }

  .news .abt {
    border: 7px solid #ffffff;
    background: linear-gradient(181deg, white, #e8dbee);
    margin-top: 40px;
    border-radius: 20px;
    padding: 30px 13px;
  }

  .abt h5 {
    color: #5b5b5b;
    padding: 5px 0px;
    font-weight: 700;
  }

  .oo {
    position: absolute;
    margin-top: -22px;
    background: linear-gradient(45deg, #5056c9, #9383e8);
    padding: 6px 20px;
    border-radius: 16px;
    color: #fff;
    font-weight: 500;
    margin-left: 41px;
  }
  .iimm{
    padding: 50px 0px;
  }
  .iimm h3 {
    padding-top: 45px;
    font-size: 31px;
}
.iimm h6 {
  font-size: 23px;
}
.iimm h5 {
  font-size: 20px;
}
.iimm .btn{
  margin-top: 30px;
  margin-left: 25px;
}
.zoom {
  transition: transform .2s;
}
.zoom:hover {
  transform: scale(1.1);
  border: 1px solid #208751;
  border-radius: 30px;
  background: linear-gradient(90deg, #208751 3%, transparent 100%);
}
.welcome{
  padding: 50px 0px;
}
.welcome .we{
  font-size: 40px;
  font-family: 'Satisfy', cursive;
}
.welcome .indi{
  line-height: 20px;
  font-weight: 600;
  font-size: 50px;
  padding: 0px;
  margin: 0px;
  color: #5b5b5b;
  /* font-family: 'Satisfy', cursive; */
}
.welcome .in{
  font-size: 50px;
    font-weight: 600;
    color: #5b5b5b;
    margin-bottom: 0px;
}
.welcome .for{
  font-size: 30px;
  font-weight: 600;
  color: #000;
}
.welcome .dow{
  font-size: 27px;
  font-weight: 600;
  color: #000;
}
.vcvc{
  padding: 40px 5px;
  background: #f0e3ff;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #b798f1;
  border-radius: 20px;
  font-weight: 600;
}
.nav-pills .nav-link {
  background: 0 0;
  border: 0;
  border-radius: 0.25rem;
  background: #dbdbdb;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: 20px;
}
nav i {
  /* display: none!important; */
  padding-right: 10px;
}
footer{
  background: #16402d;
  padding: 30px 0px;
}
footer p{
  color: #dbdbdb;
}
footer h6{
  color: #e8dbee;
}
footer h5{
  color: #e8dbee;
  font-size: 17px;
}
.copyy{
  background: #2b2a29;
}
.bggb{
  background: url(../public/28.png);
}
.bggb .pp1{
  color: #000;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 0px;
}
.bggb .pp2{
  color: #5057cc;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 0px;

  padding-bottom: 5px;
}
.bggb .pp3{
  color: #000;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-bottom: 5px;
}
footer img{
  width: 14%;
}
.aza{
  padding: 40px 0px;
}
.aza h3{
  padding-top: 20px;
    color: #5056c9;
    font-weight: 700;
}
.aza .bh1{
  background: url(../public/27.png);
  border-radius: 20px;
  padding: 15px 5px;
}
.aza .bh2{
  background: url(../public/28.png);
  border-radius: 20px;
  padding: 15px 5px;
}
.wew{
  display: none;
}











}

@media(max-width:1920px) {}

@media(max-width:1440px) {}

@media(max-width:1024px) {
  .lloo h6{
    font-size: 13px;
  }
  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 14px;
    /* padding-left: 50px; */
    padding: 0px;
}
.carro {
 margin-top: -57px;
}
  .Apogo {
    width: 70%!important;
 }
  .menutop p {
    font-size: 14px;
}
  nav ul{
    background: rgb(121, 99, 210);
    padding: 10px 5px;
  }
  .oo {
    margin-top: -22px;
    padding: 6px 10px;
    margin-left: 28px;
}
}

@media(max-width:768px) {
  nav i {
    display:block!important;
  }
}

@media(max-width:576px) {
  .navbar-light .navbar-nav .nav-link {
    color: rgb(255 255 255);
    font-weight: 600;
    font-size: 18px;
    /* padding-left: 50px; */
    padding: 0px;
}
  .uu{
    font-size: 21px;
  }
  .lloo h6{
    font-size: 15px;
  }
  .ewe{
    display: none!important;
  }
  .sticky-top {
    background: #7963d2;
}
  .welcome .we {
    font-size: 35px;
}
.welcome .indi {
  font-weight: 700;
  font-size: 40px;
}
.welcome .in {
  font-size: 40px;
  font-weight: 700;
}
.welcome .for {
  font-size: 27px;
  font-weight: 600;
}
  .menutop p {
    font-size: 18px;
}
  .bggb{
   padding-left: 10px;
   padding-right: 10px;
  }
  footer img{
    width: 30%;
  }
  .iimm .btn {
    margin-top: 9px;
    margin-left: 0px;
}
  .carro {
    margin-top: 0px;
  }
  nav {
    background: rgb(121, 99, 210);
  }
  .oo {
    
    margin-top: -24px;
    padding: 6px 80px;
    margin-left: 64px;
}
}

@media(max-width:425px) {
  .oo {
    margin-top: -25px;
    padding: 6px 50px;
    margin-left: 49px;
}
}

@media(max-width:375px) {
  .lloo h6{
    font-size: 13px;
  }
  .oo {
    
    padding: 6px 30px;
    
}
}

@media(max-width:320px) {
  .oo {
    padding: 5px 10px;
}
}